import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  Cubes,
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceBanner,
  ReferenceContainer,
  TitleH2,
  ReferenceBannerContent,
} from "@igloonet-web/shared-ui"

import Logo from "../../../images/reference/domy-dnes/logo.png"
import Top from "../../../images/reference/domy-dnes/top.png"
import Wireframe from "../../../images/reference/domy-dnes/wireframe1.png"
import Mid from "../../../images/reference/domy-dnes/timeline.png"
import Design from "../../../images/reference/domy-dnes/design.png"
import Kepi from "../../../images/team/mini/kepi.jpg"
import Tesar from "../../../images/reference/domy-dnes/tesar.jpg"

class DomyDnes extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Nový web pro DOMY D.N.E.S. | igloonet</title>
          <meta
            name="description"
            content="Reference vytvoření nového webu pro experty na dřevostavby DOMY D.N.E.S."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//domy-dnes.cz"
          heading="Vytvoření nového webu"
          odstavec="srpen 2014 - listopad 2020"
        >
          <ReferenceBannerContent
            mainText="Prvotní zadání: vytvořit nový, reprezentativní web, který zvýší počet
            objednávek z internetu a umožní přidávat aktuální informace."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Cíle webu</TitleH2>
              <ul>
                <li>Získávat relevantní objednávky přímo z webu.</li>
                <li>Umožnit snadnou prezentaci aktuálních typových domů.</li>
                <li>Představit rozestavěné projekty.</li>
              </ul>
            </PaddingWrapper>
          </HalfWidthBlock>

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Wireframe}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Analýza a wireframy</TitleH2>

              <p>
                Protože má nový web sloužit primárně potenciálním zákazníkům,
                započali jsme analýzu hloubkovými rozhovory s těmi, co s DOMY už
                stavěli či stavět teprve plánují.
              </p>
              <p>
                Na výslednou podobu webu měl velký vliv workshop se zaměstnanci
                DOMŮ, kteří se v průběhu prací s koncovými zákazníky potkávají.
              </p>
              <Cubes
                data={[
                  {
                    description: "Hloubkových rozhovorů",
                    number: "5",
                  },
                  {
                    description: "Workshopy s klientem",
                    number: "3",
                  },
                  {
                    description: "Hodin nad wireframy",
                    number: "30",
                  },
                ]}
              />
            </BlockImageTextHalf>
          </PaddingWrapper>
        </ReferenceContainer>

        <img src={Mid} alt="" className="img-fluid mb-4" loading="lazy" />

        <ReferenceContainer>
          <Medailon
            wide
            image={Kepi}
            alt=""
            text="Už po prvních dvou rozhovorech s koncovými zákazníky se začala prvotní
            „dojmová“ koncepce nového webu hroutit."
            name="Ondra Kudlík"
            position="UX na projektu"
          />

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Design}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Design</TitleH2>

              <p>
                U designu jsme se zapotili nejvíc. Navrhovat jej pro společnost,
                kde výsledek hodnotí architekti, prostě není nejsnadnější.
                Zásadně ale pomohlo, že klient od začátku důvěřoval našemu
                úsudku a zjištěným informacím.
              </p>
            </BlockImageTextHalf>
          </PaddingWrapper>

          <Medailon
            wide
            image={Tesar}
            alt=""
            text="V igloonetu nás rychle přesvědčili, že tak jako nemá smysl
            stavět dům bez projektu, nemá smysl vytvářet web bez analýzy a rozmýšlení.
            Museli jsme sáhnout hlouběji do kapsy, než jsme si představovali,
            ale výsledek stojí za to."
            name="Tomáš Tesař"
            position="jednatel DOMY D.N.E.S."
          />

          <PaddingWrapper>
            <HalfWidthBlock>
              <TitleH2>Shrnutí</TitleH2>
              <p>
                Vytvoření nového webu trvalo, kvůli vytíženosti klienta, mnohem
                déle, než je obvyklé. Díky provedené analýze, rozhovorům a
                dostatku informací se nám však i přes značný časový odstup
                začátku a konce projektu podařilo spustit relevantní a moderní
                web, který plní stanovené cíle.
              </p>
            </HalfWidthBlock>
          </PaddingWrapper>
        </ReferenceContainer>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default DomyDnes
